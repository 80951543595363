/** @jsx jsx */
import { jsx, Flex } from 'theme-ui'
import { useTranslate } from '@chordcommerce/gatsby-theme-autonomy'
import RadioOption from '../../RadioOption'
import PerBoxPrice from '../../PerBoxPrice'
import Text from '~/components/IntlComponents/Text'

export default function RadioDisplayButtons({
  presentation,
  register,
  setDefaultInterval,
  perBoxPrice,
  onChange,
  discountPercentage,
  alternate,
  resetSubscription,
  inFooter,
  value,
  name,
  price,
  forwardSx,
  localeSubscriptionToggle,
  promoDiscountPrice,
  promoDiscountPercentage,
}) {
  const translate = useTranslate()
  const SharedTextComponent = (
    <Text
      sx={{
        fontWeight: 100,
        textTransform: 'lowercase',
        fontFamily: 'body',
      }}
    >
      {translate('product.per_box')}
    </Text>
  )

  const RadioOptionSet = ({
    RightComponent,
    value,
    selected,
    BottomComponent,
    presentation,
  }) => {
    return (
      <RadioOption
        forwardSx={forwardSx}
        textStyle={{
          fontSize: alternate && [null, '12px', 'unset'],
          color: inFooter ? '#FF2815' : 'inherit',
        }}
        selected={selected}
        onChange={e => onChange(e)}
        className="subscribeSelector"
        name={name}
        value={value}
        {...register(name)}
        onClick={e => onChange(e)}
        presentation={presentation}
        RightComponent={RightComponent}
        BottomComponent={BottomComponent}
      />
    )
  }
  const sharedStyle = {
    gap: 2,
    pb: !alternate && '20px',
    flexDirection: alternate ? 'row' : 'column',
    textAlign: 'left',
    color: 'secondary',
  }

  if (localeSubscriptionToggle)
    return (
      <Flex
        sx={{
          ...sharedStyle,
        }}
      >
        <RadioOptionSet
          selected={value === 'subscribe-and-save'}
          value="subscribe-and-save"
          presentation={presentation}
          RightComponent={
            !alternate && (
              <PerBoxPrice
                TextComponent={SharedTextComponent}
                price={perBoxPrice * (1 - discountPercentage / 100)}
              />
            )
          }
        />

        <RadioOptionSet
          selected={value === 'one-time-purchase'}
          value="one-time-purchase"
          onClick={resetSubscription}
          presentation={translate('subscriptions.one_time_purchase')}
          RightComponent={
            !alternate && (
              <PerBoxPrice
                TextComponent={SharedTextComponent}
                price={perBoxPrice}
              />
            )
          }
        />
      </Flex>
    )

  return (
    <Flex
      sx={{
        ...sharedStyle,
      }}
    >
      <RadioOptionSet
        selected={value === 'subscribe-and-save'}
        value="subscribe-and-save"
        presentation={presentation}
        RightComponent={
          !alternate && (
            <PerBoxPrice price={price * (1 - discountPercentage / 100)} />
          )
        }
        BottomComponent={
          <Text
            sx={{
              fontSize: '12px',
              fontFamily: value === 'subscribe-and-save' ? 'heading' : 'body',
            }}
          >
            {translate('product.subscription_per_box', {
              price: (perBoxPrice * (1 - discountPercentage / 100)).toFixed(2),
            })}
          </Text>
        }
      />

      <RadioOptionSet
        selected={value === 'one-time-purchase'}
        value="one-time-purchase"
        presentation={translate('subscriptions.one_time_purchase')}
        onClick={resetSubscription}
        RightComponent={
          !alternate && (
            <PerBoxPrice
              price={price}
              styles={
                promoDiscountPrice && {
                  color: 'override.altButtonBackground',
                  textDecoration: 'line-through',
                  marginRight: '0.5rem',
                }
              }
              TextComponent={
                promoDiscountPrice && (
                  <Text sx={{ color: 'secondary', textDecoration: 'none' }}>
                    ${promoDiscountPrice?.toFixed(2)}
                  </Text>
                )
              }
            />
          )
        }
        BottomComponent={
          promoDiscountPrice && (
            <Text
              sx={{
                fontSize: '12px',
                fontFamily: 'heading',
                color: 'override.altButtonBackground',
              }}
            >
              Limited time offer {promoDiscountPercentage}% off.
            </Text>
          )
        }
      />
    </Flex>
  )
}
