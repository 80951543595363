/** @jsx jsx */

import { useMemo, useState } from 'react'
import { Grid, jsx, Button, Flex } from 'theme-ui'
import React from 'react'
import { useTranslate } from '@chordcommerce/gatsby-theme-autonomy'
import { IngredientsAccordion } from './IngredientsAccordion'
import { IngredientAccordionItem } from './IngredientAccordionItem'
import { IngredientLabelModal } from './IngredientLabelModal'
import Text from '~/components/IntlComponents/Text'

export const Ingredients = ({
  product = {},
  productIngredientRefObjectIndex,
  ingredientSections,
  setIndex,
  ingredientNutritionLabels,
}) => {
  const [openModal, setOpenModal] = useState(false)
  const onOpenModal = () => setOpenModal(true)
  const onCloseModal = () => setOpenModal(false)

  const translate = useTranslate()

  const ingredientBuilder = ingredientSections => {
    const productReferences =
      ingredientSections?.reduce((acc, value) => {
        if (
          value?.__typename === 'ContentfulProduct' &&
          value?.ingredientSections
        ) {
          return [
            ...acc,
            {
              ingredientSections: value?.ingredientSections?.filter(
                ingredientSection =>
                  ingredientSection.__typename === 'ContentfulIngredientList',
              ),
              productName: value?.name,
            },
          ]
        }
        return acc
      }, []) ?? []

    const normalIngredients =
      ingredientSections?.reduce((acc, value) => {
        if (value?.__typename === 'ContentfulIngredientList') {
          return [...acc, value]
        }
        return acc
      }, []) ?? []
    return [productReferences, normalIngredients]
  }

  const [productIngredientListReferences, normalIngredientList] = useMemo(
    () => ingredientBuilder(ingredientSections),
    [ingredientSections],
  )

  const [currentProduct, setCurrentProduct] = useState(
    productIngredientListReferences?.[0]?.ingredientSections ?? [],
  )

  const [noodlesIngredients, cheeseIngredients] = useMemo(
    () => [...currentProduct, ...normalIngredientList],
    [currentProduct, normalIngredientList],
  )

  const handleChangeIngredientSections = (index, ingredientSections) => {
    setIndex(index)
    setCurrentProduct(ingredientSections)
  }

  const nutrientsExtractedFromText = 'nutrients-extracted-from'

  const noodlesIngredientsFilter = noodlesIngredients?.ingredients?.filter(
    ing => ing.slug !== nutrientsExtractedFromText,
  )

  const nutrientsExtractedFrom = noodlesIngredients?.ingredients?.find(
    ing => ing.slug === nutrientsExtractedFromText,
  )

  const isGlutenFree = product?.slug?.includes('gluten')
  const firstLineIconNumber = isGlutenFree ? 5 : 3
  return (
    <Grid
      columns={1}
      sx={{
        justifyItems: 'center',
        color: 'secondary',
        position: 'relative',
        padding: '23px',
        mb: ['50px', '100px'],
        gap: '0px',
      }}
    >
      <Button
        variant="text.eyebrow.uppercase"
        sx={{
          cursor: 'pointer',
          backgroundColor: 'override.stickyATCBg',
          color: 'override.stickyATCColor',
          textDecoration: 'underline',
          textDecorationThickness: 2,
          fontSize: ['20px', '24px'],
          mb: '18px',
          padding: ['20px 20px', '20px 48px'],
          borderRadius: '20px',
          width: '100%',
          maxWidth: '500px',
        }}
        onClick={onOpenModal}
      >
        {translate('product.open_nutritional_modal')}
      </Button>

      <Text
        variant="text.h3"
        sx={{
          fontWeight: '400',
          fontSize: ['32px', '40px'],
          mb: '25px',
          textTransform: 'uppercase',
        }}
      >
        {translate('product.ingredients_title')}
      </Text>

      {productIngredientListReferences && (
        <Grid
          columns={[2, '1fr repeat(3, auto)']}
          sx={{
            mb: '10px',
          }}
        >
          {productIngredientListReferences.map((prodObj, i) => {
            return (
              <div
                key={productIngredientListReferences.id}
                sx={{
                  cursor: 'pointer',
                  textDecoration:
                    productIngredientRefObjectIndex === i
                      ? 'underline solid'
                      : 'none',
                }}
                tabIndex="0"
                role="button"
                onClick={() =>
                  handleChangeIngredientSections(i, prodObj.ingredientSections)
                }
                onKeyDown={() =>
                  handleChangeIngredientSections(i, prodObj.ingredientSections)
                }
              >
                {prodObj.productName}
              </div>
            )
          })}
        </Grid>
      )}

      {noodlesIngredients?.ingredients?.length && (
        <IngredientsAccordion title={noodlesIngredients.title}>
          <Flex
            sx={{
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Flex
              sx={{
                gap: ['0px', '96px'],
                justifyContent: 'space-between',
              }}
            >
              {noodlesIngredientsFilter
                ?.slice(0, firstLineIconNumber)
                .map(ing => (
                  <IngredientAccordionItem
                    key={ing.id}
                    forwardSx={{
                      flex: ['1 1 0px', null],
                    }}
                    {...ing}
                  />
                ))}
            </Flex>

            {nutrientsExtractedFrom && (
              <Text
                sx={{
                  my: ['16px', '32px'],
                  fontSize: ['18px', '32px'],
                  fontWeight: '500',
                }}
              >
                <IngredientAccordionItem
                  forwardSx={{
                    flex: [null],
                  }}
                  {...nutrientsExtractedFrom}
                />
              </Text>
            )}

            <Flex
              sx={{
                gap: ['19px 0px', '48px 96px'],
                flexWrap: 'wrap',
                justifyContent: ['space-between', 'center'],
              }}
            >
              {noodlesIngredientsFilter?.slice(firstLineIconNumber).map(ing => (
                <IngredientAccordionItem key={ing.id} {...ing} />
              ))}
            </Flex>
          </Flex>
        </IngredientsAccordion>
      )}

      {cheeseIngredients?.ingredients?.length && (
        <IngredientsAccordion
          title={cheeseIngredients.title}
          refresh={productIngredientRefObjectIndex}
        >
          <Flex
            sx={{
              gap: ['19px 0px', '48px 96px'],
              flexWrap: 'wrap',
              justifyContent: ['space-between', 'center'],
            }}
          >
            {cheeseIngredients?.ingredients?.map(ing => (
              <IngredientAccordionItem key={ing.id} {...ing} />
            ))}
          </Flex>
        </IngredientsAccordion>
      )}

      <IngredientLabelModal
        onCloseModal={onCloseModal}
        ingredientNutritionLabels={ingredientNutritionLabels}
        openModal={openModal}
      />
    </Grid>
  )
}
